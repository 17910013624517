<template>
  <b-row>
    <b-col md="7">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 
        
        <div slot="header">
          <strong> Control </strong>
        </div>
        
        <b-row>            
          <b-col md="12">      
            <b-form-group label="Imagen">
              <Imagen :images="block.control.image" 
                      @load-image="block.control.image = $event" 
                      typeImage="Imagen"
                      :multipleImage="true"
                      :compressImage="true"/>
            </b-form-group>  
          </b-col>
        </b-row>      
      </b-card>
    </b-col>
    <b-col md="5">
      <b-card header-text-variant="white"
              header-bg-variant="dark"
              header-border-variant="dark"
              footer-text-variant="dark"
              footer-bg-variant="dark"
              footer-border-variant="secondary"> 

        <div slot="header">
          <strong> Configuración </strong>
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Título">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.title"
                            placeholder="Ingresar un título">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Subtítulo">
              <b-form-input type="text"
                            size="sm"
                            v-model="block.config.subtitle"
                            placeholder="Ingresar un subtítulo">
              </b-form-input>
            </b-form-group>
          </b-col>                    
          <b-col md="12">
            <b-form-group label="Color de Texto">
              <b-form-input v-model="block.config.fontColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Color de Fondo ">
              <b-form-input v-model="block.config.backgroundColor" type="color"></b-form-input>
            </b-form-group>
          </b-col>      
          <b-col md="12">      
            <b-form-group label="Imagen de Fondo" description="Imagen 1: se utiliza para desktop | Imagen 2: se uliza para mobile (si no esta cargada se usa la imagen 1)">                          
              <Imagen :images="block.config.backgroundImage" 
                      @load-image="block.config.backgroundImage = $event" 
                      typeImage="BackgroundImage"
                      :multipleImage="true"
                      :maxImage="2" 
                      :compressImage="true"/>
            </b-form-group>  
          </b-col>  
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingTop" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Superior?
            </b-form-checkbox>            
          </b-col>           
          <b-col md="12">
            <b-form-checkbox v-model="block.config.havePaddingBottom" 
                              switch 
                              size="sm" 
                              class="mt-4 pull-right">
              ¿Tiene Padding Inferior?
            </b-form-checkbox>            
          </b-col>    

          <b-col md="12">
            <b-form-group label="Estilos" description="Las clases CSS ingresadas se aplicarán solo a este bloque">
              <prism-editor v-model="block.config.stylesCSS" language="css" class="content-crud-block-css"></prism-editor>            
            </b-form-group>
          </b-col>           

        </b-row>
      </b-card>
    </b-col>
  </b-row>  
</template>
<script>
  import Imagen from '@/components/inc/image/image'
  import { VueEditor } from "vue2-editor";
  import PrismEditor from 'vue-prism-editor'
  import "prismjs";
  import "prismjs/themes/prism.css";

  export default {
    components: {   
      PrismEditor,      
      Imagen,
      VueEditor
    },  
    props: {
      dataBlock:{
        type: Object,
        default: {}
      },      
    },    
    data: () => {
      return {   
        block: {},   
        render: false,        
      }
    },   
    created() {               
      if(!this.dataBlock.control) {
        this.block.control = { 
          image: [],               
        },
        this.block.config = {
          title: '',
          subtitle: '',
          fontColor: '#000000',
          backgroundColor: '#ffffff',
          backgroundImage: [],
          havePaddingBottom: true,
          havePaddingTop: true,
          stylesCSS: '',
        }
        this.block.images = ['image'],
        this.block.imagesConfig = ['backgroundImage'],

        this.emit()
        this.render = true
      }
      else {                     
        var img = []  
        var i = 0     
        var image = 100        
        if(image) {          
          for (i; i < image; i++) {    
            if(this.dataBlock.control["image_" + i]) {                    
              img.push({ path: this.dataBlock.control["image_" + i] })
            }
          }          
        } 

        this.block.control= {           
          image: img,         
        }

        var imgConfg = []  
        var i = 0         
        var backgroundImage = 2
        if(backgroundImage) {          
          for (i; i < backgroundImage; i++) {                        
            if(this.dataBlock.config["backgroundImage_" + i]) {
              imgConfg.push({ path: this.dataBlock.config["backgroundImage_" + i] })
            }   
          }          
        }       

        this.block.config= { 
          title: this.dataBlock.config.title,
          subtitle: this.dataBlock.config.subtitle,
          fontColor: this.dataBlock.config.fontColor,
          backgroundColor: this.dataBlock.config.backgroundColor,  
          backgroundImage: imgConfg,
          havePaddingBottom: this.dataBlock.config.havePaddingBottom,
          havePaddingTop: this.dataBlock.config.havePaddingTop,
          stylesCSS: this.dataBlock.config.stylesCSS,
        }

        this.block.images = ['image']
        this.block.imagesConfig = ['backgroundImage']

        this.emit()
        this.render = true
      }
    },
    methods: {
      emit() {        
        this.$emit('get-object', this.block) 
      },
    }        
  }
</script>