import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerCategoria() {
    var url = ConfigAPI.baseURL + "products-category" + Session.getToken();
    return instance.get(url);
  }, 
  obtenerLinea() {
    var url = ConfigAPI.baseURL + "products-line" + Session.getToken();
    return instance.get(url);
  },
  obtenerCategoriaConSubcategorias() {
    var url = ConfigAPI.baseURL + "products-category/find/categoriesWithSubcategories" + Session.getToken();
    return instance.get(url);
  },
}

export default servicesAPI;
